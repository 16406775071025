import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Justin J Klein | Development ', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'JustinKlein-s.jpg',
  paragraphOne:
    'As an expert fullstack software engineer, Justin has worked on enterprise web applications and integrations, mobile development and scalable backend architectures. Leveraging over a decade of experience developing and consulting across multiple industries, Justin excels at translating business and product needs into technical requirements, and implementing creative tech solutions to facilitate growth.',
  paragraphTwo:
    'He has spent the last 5 years developing and deploying software solutions for the U.S. healthcare system. Justin is currently focused on delivering Machine Learning, Blockchain/DLP and Information Security techniques and products to the U.S. healthcare market.',
  paragraphThree:
    'Please reach out if you are interested in working with me or getting some advice. I am very happy to assist!',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'softwareengineering.jpg',
    title: 'System Architecture and Software Engineering',
    info:
      'In todays digital world there are always opportunities to expand on concepts or automate and alleviate burdens with a bit of clever engineering.',
    info2:
      'Limits should not be set by the toolsets that are currently in place. There is little more I enjoy than taking a dream into reality',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'datascience.jpg',
    title: 'ML & Data Science',
    info:
      'In a world full of data it is figuring out what really matters and how to properly measure it in context. ',
    info2: 'Use your data to automate processes and discover new insights',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'security.jpg',
    title: 'Security & Compliance',
    info:
      'In todays world security and compliance are a requirement. It is not enough to build in a secure environment. Security must be built into your systems and workflows. ',
    info2:
      'Use my expertise to limit your risk and exposure while demonstrating compliance. Meeting Security & Privacy reqs in a streamlined, consistent and efficient manner may seem complex and overwhelming. Leverage my skills to simplify this task.',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: '',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: '',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
